import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
  // Snackbar,
  // Alert,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ChatBoxMessagesStyles, textfieldLayout } from "./CustomerBotStyles";
import colors from "../../Constants/colors";

const CustomerBot = () => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  // const [snackbarMessage, setSnackbarMessage] = useState("");
  const [generatingMessage, setGeneratingMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState("only_bot");

  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const apiKeysByBotId = {
    "6790d78e3cad892a0b5b114e":
      "qlp_gAAAAABnkNmbtAOIFnPc27Ir1gBIwxBdR7DVcsu9dtebH0dLQKhpJ-bQFfdDwgBEaqlVnfPqJQliQEAqNZwpo-FZTP23iBB-dMIZZFldtYT6iMySOV7uoec=qlpgAAAAABnkNmb-tv8mtyLEkoC39g8vbBNTu_3rd3D0dHEc3GE1p2-hJq2iFUEyPllEbid-fuauypPYeu6J8CLNbYGRQkERcQqKpXLjlAW2Xm-_b7IbJSugRs=",
    "678e85fe03a3b98ebffc0433":
      "qlp_gAAAAABnjo7GZx81yAkUktsj2ttTShMKy__kiN55290Ss-a39i3h8DEwkLF8-3ycAGndIFWGn6FuKD56ngfqs6BS-wQJqOuNi41eeRT77s0eruC6rZP6o4Q=qlpgAAAAABnjo7GrQ77Q5HNQ4ObyamUSXA03MYQn-rYCNAuSlHTkFPFR6R7oJHRtsfQF0QBjUufMYb0MzE76WGn4JYNczMqrHP3gwaIyLYKWZ7NWpKbpfcqHk4=",
    "6790d21a7154aba53486c2ec":
      "qlp_gAAAAABnkNZQxRyABToExCyky81dUKIQAS043xlvM35uzj41vvcLwpKJpboIW_HXIA5iCOz3sJ5c6suFpcajPCmTnBPicSaFNPHF2K2m_Gqu42vPgVI7X2g=qlpgAAAAABnkNZQ5gT7Gz0dk43I7MehNrvJt4We7cxorhtLNjm93DACORCUPIpz8EAOO3zsj8AzrhUEx0YYKjxpFKYE39k6eiSDohuSQrbYCMBTAFYX3JURVm0=",
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handleSnackbarClose = () => {
  //   setSnackbarOpen(false);
  // };

  // Scroll to the bottom whenever messages change

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    // Add the user's message to the conversation
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "user", text: newMessage },
    ]);

    const userMessageIndex = messages.length; // Track the index of the user's message

    setNewMessage("");
    setIsLoading(true);

    // Add a placeholder for the bot's response
    setMessages((prevMessages) => [
      ...prevMessages,
      { sender: "bot", text: "Generating response..." }, // Single message placeholder
    ]);

    setGeneratingMessage(true);

    const url = "https://demo.queryloop-ai.com/api/developer/use";

    const apiKeys = [
      apiKeysByBotId["678e85fe03a3b98ebffc0433"], // First response key
      apiKeysByBotId["6790d21a7154aba53486c2ec"], // Second response key
    ];

    const payload = {
      query: newMessage,
      verification_token:
        "2526d5d116af1dd8f56c5a534abc8bfa17bfe609534f2a8a230064ed97ed3c68",
    };

    try {
      if (selectedOption === "bot_group") {
        // Fetch all responses in parallel
        const fetchPromises = apiKeys.map((apiKey) =>
          fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              apiKey: apiKey,
            },
            body: JSON.stringify(payload),
          })
        );

        const responses = await Promise.all(fetchPromises);

        // Process the first bot's response with streaming
        const firstReader = responses[0].body.getReader();
        const firstDecoder = new TextDecoder("utf-8");
        let firstBotMessage = "";

        // Initially show only response 1 while it's being streamed
        while (true) {
          const { done, value } = await firstReader.read();
          if (done) break;
          firstBotMessage += firstDecoder.decode(value, { stream: true });

          const messageToDisplay = `**Clear Edge Prime Assistant Response:**\n${firstBotMessage}`;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[userMessageIndex + 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {messageToDisplay}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }

        // Once the first response is done, stream the second response
        const secondReader = responses[1].body.getReader();
        const secondDecoder = new TextDecoder("utf-8");
        let secondBotMessage = "";

        // Now show response 1 and start showing response 2
        while (true) {
          const { done, value } = await secondReader.read();
          if (done) break;
          secondBotMessage += secondDecoder.decode(value, { stream: true });

          const messageToDisplay = `**Clear Edge Prime Assistant Response:**\n${firstBotMessage}\n\n**Clear Edge Investor Assistant Response:**\n${secondBotMessage}`;
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[userMessageIndex + 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {messageToDisplay}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }
      } else {
        // Single API call for "only_bot"
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            apiKey: apiKeysByBotId["6790d78e3cad892a0b5b114e"], // Use the first API key
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let botMessage = "";

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          botMessage += decoder.decode(value, { stream: true });

          // Update the single bot message using ReactMarkdown
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1].text = (
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {botMessage}
              </ReactMarkdown>
            );
            return updatedMessages;
          });
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: "Error fetching response" },
      ]);
    } finally {
      setIsLoading(false);
      setGeneratingMessage(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "100vh",
        px: 8,
        bgcolor: "white",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "95%",
          backgroundColor: colors.extremeBlue,
          mt: "20px",
          boxShadow: `0px 0px 70px ${colors.secondaryBlue}`,
          py: 2,
          px: 4,
          height: "93vh",
          borderRadius: "8px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: `1px solid ${colors.extremeWhite}`,
            pb: 2,
            my: 1,
          }}
        >
          {/* <IconButton
          onClick={handleBackClick}
          sx={{
            color: colors.extremeWhite,
            backgroundColor: colors.primaryBlue,
            "&:hover": {
              backgroundColor: colors.tertiaryBlue,
            },
            boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
            marginRight: 2,
          }}
        >
          <ArrowBackIcon />
        </IconButton> */}
          <Typography
            variant="h5"
            sx={{
              flex: 1,
              textAlign: "center",
              color: colors.extremeWhite,
            }}
          >
            Chat with Clear Edge Assistant
          </Typography>
        </Box>

        <Box sx={ChatBoxMessagesStyles}>
          {(messages || []).map((message, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent:
                  message.sender === "user" ? "flex-end" : "flex-start",
                mb: 2,
              }}
            >
              <Box
                sx={{
                  maxWidth: "70%",
                  padding: "10px 15px",
                  borderRadius: "15px",
                  backgroundColor:
                    message.sender === "user"
                      ? colors.primaryBlue
                      : colors.textFieldBackground,
                  color: colors.extremeWhite,
                  boxShadow: `0px 4px 6px ${colors.secondaryBlue}`,
                  // "&::after": message.text === "Generating" && {
                  //   content: "'...'",
                  //   display: "inline-block",
                  //   animation: "dots 1.5s steps(1, end) infinite",
                  // },
                  // "@keyframes dot-blink": message.text === "Generating" && {
                  //   "0%": { content: '"."' }, // 1 dot
                  //   "33%": { content: '".."' }, // 2 dots
                  //   "66%": { content: '"..."' }, // 3 dots
                  //   "100%": { content: '""' }, // No dots (restarts)
                  // },
                }}
              >
                {/* Render the message content */}
                {message.sender !== "user" &&
                !generatingMessage &&
                typeof message.text === "string" ? (
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {message.text}
                  </ReactMarkdown>
                ) : (
                  message.text
                )}
              </Box>
            </Box>
          ))}
          <div ref={messageEndRef} />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            placeholder="Type your message..."
            fullWidth
            sx={textfieldLayout}
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={isLoading || generatingMessage}
          />
          <IconButton
            sx={{
              color: colors.extremeWhite,
              backgroundColor: colors.primaryBlue,
              "&:hover": {
                backgroundColor: colors.tertiaryBlue,
              },
              "&.Mui-disabled": {
                backgroundColor: colors.darkGray,
                color: colors.lightGray,
              },
            }}
            onClick={handleSendMessage}
            disabled={isLoading || generatingMessage}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar> */}
      </Box>
      {/* Radio Groups Section */}
      <FormControl sx={{ ml: 4, mt: 2 }}>
        <RadioGroup
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tooltip title="Queries all lender guidelines together and creates a best response">
            <FormControlLabel
              value="only_bot"
              control={<Radio />}
              label="Unified Best Response"
            />
          </Tooltip>
          <Tooltip title="Queries lender guidelines individually and generates a response as per each lender separately">
            <FormControlLabel
              value="bot_group"
              control={<Radio />}
              label="Individual Lender Responses"
            />
          </Tooltip>

          {/* <FormControlLabel
            value="only_bot_gen"
            control={<Radio />}
            label="Unified Response 2"
          /> */}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default CustomerBot;
